import React from 'react';

import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Text,
  useTheme,
  DrawerBody,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { UserIcon } from '@/assets/icons/UserIcon';
import Logo from '@/assets/images/logo.png';

import { LoginButton } from '@/components/Button/LoginButton';
import { LogoutButton } from '@/components/Button/LogoutButton';
import { SignupButton } from '@/components/Button/SignupButton';

import { useSidebarContext } from '@/context/sidebar.context';
import { useUserContext } from '@/context/user.context';

import { RoutesEnum } from '@/enum/routes.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

export const Sidebar: React.FC = () => {
  const { isSidebarOpen, onSidebarClose } = useSidebarContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { value: jwt } = useLocalStorage(StorageKeysEnum.jwt);

  const goToHome = () => {
    onSidebarClose();
    navigate(RoutesEnum.HOME);
  };

  return (
    <Drawer isOpen={isSidebarOpen} onClose={onSidebarClose} placement='left'>
      <DrawerOverlay />
      <DrawerContent backgroundColor='#272833'>
        <DrawerCloseButton color={theme.colors.sidebar.close} />
        <DrawerHeader pt='50px'>
          <Button onClick={goToHome} variant='ghost'>
            <Image alt='' maxW='200px' src={Logo} />
          </Button>
        </DrawerHeader>

        <DrawerBody>
          {user && (
            <Button
              alignItems='center'
              color={theme.colors.sidebar.text}
              display='flex'
              fontSize='17px'
              fontWeight='700'
              justifyContent='flex-start'
              leftIcon={<UserIcon />}
              maxW='100%'
              onClick={() => navigate(RoutesEnum.PROFILE)}
              px='5px'
              variant='unstyled'
              w='fit-content'
            >
              <Text noOfLines={1}>{user.username}</Text>
            </Button>
          )}
        </DrawerBody>

        <DrawerFooter flexDirection='column'>
          <Flex align='center' gap='10px' justify='space-between' w='100%'>
            {jwt ? (
              <LogoutButton />
            ) : (
              <>
                <LoginButton />
                <SignupButton />
              </>
            )}
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
