import { Button } from '@chakra-ui/react';

import { useJWT } from '@/hooks/useJWT';
import { useLogout } from '@/hooks/useLogout';

export const LogoutButton = () => {
  const { jwt } = useJWT();
  const { handleLogout } = useLogout();

  if (!jwt) return null;

  return (
    <Button
      onClick={handleLogout}
      size='md'
      textTransform='uppercase'
      variant='primary'
    >
      Log out
    </Button>
  );
};
