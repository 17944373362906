import { ENABLED_LANGUAGES } from '@/utils/constants';

import en from './locales/en/translation.json';
import rus from './locales/ru/translation.json';

const LANGUAGES = {
  en: 'EN',
  es: 'EE',
  esp: 'ES',
  fr: 'FR',
  lt: 'LV',
  sl: 'SK',
  rus: 'RU',
} as const;

export const DEFAULT_LANGUAGE = en;

export type LanguageCodeType = keyof typeof LANGUAGES;
export type LanguageNameType = (typeof LANGUAGES)[LanguageCodeType];

export const enabledLanguages = (ENABLED_LANGUAGES?.split(',').filter((lang) =>
  Object.keys(LANGUAGES).includes(lang),
) as LanguageCodeType[]) || ['en'];

export const resources = {
  en: { translation: en },
  // es: { translation: es },
  // lt: { translation: lt },
  rus: { translation: rus },
  // sl: { translation: sl },
};

export type TFunc = (key: string) => string;
export type tKeysUnion = keyof typeof DEFAULT_LANGUAGE;
export type tKeysType = { [key in tKeysUnion]: tKeysUnion };

export const tKeys = Object.fromEntries(
  Object.keys(en).map((key) => [key, key]),
) as tKeysType;

export const getLanguageNameByCode = (
  code: LanguageCodeType,
): LanguageNameType => LANGUAGES[code] || 'English';
