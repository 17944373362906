import { useEffect } from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLogout } from '@/hooks/useLogout';

export const useAuth = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { handleLogout } = useLogout();

  const token = searchParams.get(StorageKeysEnum.token);

  useEffect(() => {
    (async () => {
      if (token === 'logout') {
        await handleLogout();
        navigate(pathname);
      }
    })();
  }, [token]);
};
