import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { RoutesEnum } from '@/enum/routes.enum';

export const LoginButton = () => {
  const navigate = useNavigate();
  const goToLogin = () => navigate(RoutesEnum.LOGIN);
  return (
    <Button onClick={goToLogin} textTransform='uppercase' variant='primary'>
      Log in
    </Button>
  );
};
