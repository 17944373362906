import { useMemo } from 'react';

import { Box, Flex, Grid, Image } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '@/assets/icons/backArrow.svg';
import Burger from '@/assets/images/OlyBet/icon/burger.svg';
import logo from '@/assets/images/logo.png';

import { useSidebarContext } from '@/context/sidebar.context';

import { RoutesEnum } from '@/enum/routes.enum';

export const MobileHeader = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { onSidebarOpen } = useSidebarContext();

  const isBackButtonVisible = useMemo(
    () => pathname === RoutesEnum.PROFILE,
    [pathname],
  );

  return (
    <>
      <Box
        as='header'
        bgColor='#050103'
        left='0'
        position='fixed'
        top='0'
        w='100%'
        zIndex='997'
      >
        <Grid gridTemplateColumns='1fr 1fr 1fr' h='80px'>
          <Flex alignItems='center' p='20px'>
            {isBackButtonVisible ? (
              <BackArrow cursor='pointer' onClick={() => navigate(-1)} />
            ) : (
              <Image
                alt='menu-icon'
                cursor='pointer'
                onClick={onSidebarOpen}
                src={Burger}
              />
            )}
          </Flex>

          <Flex justify='center'>
            <Image maxW='200px' src={logo} />
          </Flex>
        </Grid>
      </Box>
    </>
  );
};
