import { ComponentStyleConfig } from '@chakra-ui/react';

const Input: ComponentStyleConfig = {
  variants: {
    primary: {
      field: {
        width: '100%',
        padding: '1px 15px',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '1',
        color: '#f4f4f6',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF1A',
        _placeholder: {
          color: 'rgba(201,201,215,0.68)',
        },
        _focus: {},
        _invalid: {
          border: '1px solid',
          borderColor: 'input.invalid',
        },
      },
    },
    readonly: {
      field: {
        backgroundColor: 'input.placeholder',
      },
    },
  },
};

export default Input;
