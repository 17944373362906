import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { RoutesEnum } from '@/enum/routes.enum';

import { triggerGTAGEvent } from '@/utils/analyticTracks';

export const SignupButton = () => {
  const navigate = useNavigate();

  const goToSignup = () => {
    navigate(RoutesEnum.REGISTRATION);
  };

  return (
    <Button onClick={goToSignup} textTransform='uppercase' variant='secondary'>
      Join Now
    </Button>
  );
};
