import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

export const useLogout = () => {
  const { removeItem: removeJWT } = useLocalStorage<string>(
    StorageKeysEnum.jwt,
  );

  const { removeItem: removeUserID } = useLocalStorage<string>(
    StorageKeysEnum.user_id,
  );

  const handleLogout = async () => {
    removeJWT();
    removeUserID();
  };

  return {
    handleLogout,
  };
};
