import { Suspense } from 'react';

import { Outlet } from 'react-router-dom';

import Header from '@/components/Header';
import { Sidebar } from '@/components/Sidebar';

import { GameZoneTemplateContextProvider } from '@/context/GameZoneTemplate.context';
import { ProgressContextProvider } from '@/context/progress.context';
import { SidebarContextProvider } from '@/context/sidebar.context';
import { UserContextProvider } from '@/context/user.context';

import { PageLoader } from '@/elements/Loaders/page-loader';

import { IS_HEADER_HIDDEN } from '@/utils/constants';

const AppLayout = () => {
  return (
    <>
      <ProgressContextProvider>
        <GameZoneTemplateContextProvider>
          <SidebarContextProvider>
            <UserContextProvider>
              {!IS_HEADER_HIDDEN && <Header />}
              <Sidebar />
              <Suspense fallback={<PageLoader />}>
                <Outlet />
              </Suspense>
            </UserContextProvider>
          </SidebarContextProvider>
        </GameZoneTemplateContextProvider>
      </ProgressContextProvider>
    </>
  );
};

export default AppLayout;
