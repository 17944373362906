import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { RoutesEnum } from '@/enum/routes.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from './useLocalStorage';

export const useJWT = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { value: jwt, removeItem: removeJWT } = useLocalStorage<string>(
    StorageKeysEnum.jwt,
  );

  useEffect(() => {
    if (jwt) {
      if (pathname === RoutesEnum.REGISTRATION) {
        navigate(RoutesEnum.HOME);
      }
    }
  }, [jwt]);

  return {
    jwt,
    removeJWT,
  };
};
