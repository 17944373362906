import { useEffect } from 'react';

import i18n from 'i18next';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';

import './App.css';

import AppLayout from '@/AppLayout';

import { useAuth } from '@/hooks/useAuth';

import { AppRoutes } from './routes';

function App() {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  useAuth();

  useEffect(() => {
    const lang = new URLSearchParams(location.search).get('lang');
    if (searchParams.has('lang')) {
      i18n.changeLanguage(lang || 'en');
      window.dispatchEvent(new Event('storage'));
      searchParams.delete('lang');
    }
  }, [location.search, searchParams]);

  return (
    <Routes>
      <Route element={<AppLayout />}>
        {AppRoutes.map((route) => (
          <Route element={route.element} key={route.path} path={route.path} />
        ))}
      </Route>
    </Routes>
  );
}

export default App;
