import React, { useMemo, useState } from 'react';

import {
  Box,
  Image,
  useTheme,
  useMediaQuery,
  Flex,
  Button,
  Text,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserIcon } from '@/assets/icons/UserIcon';
import { ReactComponent as BackArrow } from '@/assets/icons/backArrow.svg';
import clatchbetHeaderLogo from '@/assets/images/ClutchBet/clutchbet-logo.png';
import Burger from '@/assets/images/OlyBet/icon/burger.svg';
import logo from '@/assets/images/logo.png';

import { LoginButton } from '@/components/Button/LoginButton';
import { LogoutButton } from '@/components/Button/LogoutButton';
import { SignupButton } from '@/components/Button/SignupButton';
import { MobileHeader } from '@/components/Header/MobileHeader';

import { useSidebarContext } from '@/context/sidebar.context';
import { useUserContext } from '@/context/user.context';

import { MediaQueriesEnum } from '@/enum/mediaQueries.enum';
import { RoutesEnum } from '@/enum/routes.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { triggerGTAGEvent } from '@/utils/analyticTracks';

const Header: React.FC = () => {
  const [isDesktop] = useMediaQuery(MediaQueriesEnum.isDesktop, { ssr: false });
  const { onSidebarOpen } = useSidebarContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { user } = useUserContext();
  const { value: jwt } = useLocalStorage<string>(StorageKeysEnum.jwt);

  const [logoLoaded, setLogoLoaded] = useState(false);

  const goToHome = () => {
    navigate(RoutesEnum.HOME);
  };

  const isHeaderShown = useMemo(
    () => pathname !== RoutesEnum.SSOENTRY,
    [pathname],
  );

  if (!isDesktop) {
    return <MobileHeader />;
  }

  return (
    <Box
      as='header'
      display={isHeaderShown ? 'block' : 'none'}
      left='0'
      position='fixed'
      top='0'
      w='100%'
      zIndex='997'
    >
      <Grid
        background='#050103'
        gridTemplateColumns='1fr 3fr 1fr'
        h='140px'
        padding='20px'
        position='relative'
        width='100%'
      >
        <Flex gap='10px'></Flex>

        <Flex
          align='center'
          mx='auto'
          w={isDesktop ? '224px' : '160px'}
          zIndex='1'
        >
          <Button onClick={goToHome} p='0' variant='ghost'>
            <Image maxW='200px' src={logo} />
          </Button>
        </Flex>

        <Flex align='center' gap='10px' justify='flex-end' w='100%'>
          {jwt ? (
            <>
              {user && (
                <Button
                  alignItems='center'
                  color={theme.colors.header.profile}
                  display='flex'
                  fontSize='17px'
                  fontWeight='700'
                  justifyContent='center'
                  leftIcon={<UserIcon />}
                  onClick={() => navigate(RoutesEnum.PROFILE)}
                  px='5px'
                  variant='unstyled'
                  w='150px'
                >
                  <Text noOfLines={1}>{user.username}</Text>
                </Button>
              )}
              <LogoutButton />
            </>
          ) : (
            <>
              <LoginButton />
              <SignupButton />
            </>
          )}
        </Flex>
      </Grid>

      {/* <Flex
        align='center'
        background={theme.colors.header.secondaryBg}
        h='44px'
        justify='center'
        mt='-1px'
        w='100%'
      >
        <Text color={theme.colors.header.text} fontSize='17px' fontWeight='400'>
          Over{' '}
          <Box as='span' color={theme.colors.header.text} fontWeight='700'>
            $1,000,000
          </Box>{' '}
          prize value won
        </Text>
      </Flex> */}
    </Box>
  );
};

export default Header;
